import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { quilTheme } from 'quil/theme';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import { PersistGate } from 'redux-persist/es/integration/react';
import enhancedStore from './store';
import { msalConfig } from './authConfig';
import App from './App';

const msalInstance = new PublicClientApplication(msalConfig);
export default msalInstance;

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <Provider store={enhancedStore.store}>
      <PersistGate loading={<LinearProgress/>} persistor={enhancedStore.persistor}>
        <Suspense>
          <Router>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
            <link
              href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
              rel="stylesheet"
            />
            <ThemeProvider theme={quilTheme}>
              <CssBaseline />
              <App pca={msalInstance} />   
            </ThemeProvider>
          </Router>
        </Suspense>
    </PersistGate>
    </Provider>,
  );
});